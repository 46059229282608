/*
The WorkForge V logo that will animate in on page load.
 */
import React from 'react';
import {animated} from "@react-spring/web";
import './Logo.css';

export default function Logo() {

    return (
        <animated.svg
            id="svg-logo"
            // style={{transform}}
            viewBox="0 0 12.7 12.7"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <linearGradient id="linearGradient905">
                    <stop stopColor="#001dcb" offset="0"/>
                    <stop stopColor="#26cb2f" offset=".37701"/>
                    <stop stopColor="#cb7400" offset=".68046"/>
                    <stop stopColor="#9400cb" offset=".91034"/>
                </linearGradient>
                <linearGradient id="linearGradient907" x1="4.7742" x2="7.9357" y1="2.6648" y2="2.6648" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient905"/>
                <linearGradient id="linearGradient3023" x1=".4093" x2="12.326" y1="7.632" y2="7.632" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient905"/>
                <linearGradient id="linearGradient937" x1="5.0271" x2="7.6729" y1="2.5973" y2="2.5973" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient905"/>
            </defs>
            <g>
                <circle cx="6.35" cy="6.35" r="6.265" stroke="url(#linearGradient905)" fill="none" strokeDashoffset="1.8166" strokeOpacity=".4" strokeWidth=".15" style={{paintOrder: "normal"}}/>
                <g strokeWidth=".079375">
                    <path d="m9.2539 2.6504-2.8945 6.127-2.8887-6.1172-0.60742 0.22852c-0.25324 0.095198-0.60429 0.18662-0.97461 0.41797-0.37032 0.23135-0.73272 0.65606-0.87305 1.2168l1.3691 0.34375c0.059358-0.2372 0.11092-0.27518 0.25195-0.36328 0.023592-0.014738 0.091271-0.034156 0.12109-0.048828l3.6035 7.625 3.6094-7.6445c0.024387 0.010413 0.083642 0.026433 0.10352 0.037109 0.13882 0.074573 0.19519 0.10678 0.2832 0.34961l1.3281-0.48047c-0.19407-0.53543-0.57942-0.91583-0.94336-1.1113-0.36394-0.1955-0.67736-0.26043-0.875-0.33789z" color="#000000" stroke="url(#linearGradient3023)" strokeWidth=".079375"/>
                </g>
                <circle cx="6.35" cy="2.5973" r="1.3229" stroke="url(#linearGradient937)" strokeWidth=".079375"/>
            </g>
        </animated.svg>
    )
}