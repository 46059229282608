/*
Our search bar that will expand from the circle logo to the full width
 */
import React, { useState, useEffect, useMemo } from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import useMeasure from 'react-use-measure';
import Logo from './Logo';
import './SearchBar.css';

export default function SearchBar() {
    // Our array of emphasis words
    const words = useMemo(() => ["communicating", "searching", "collaborating", "work"], []);
    const [wordIndex, setWordIndex] = useState(0);
    const [fadedOut, setFadedOut] = useState(false);

    // Use measure to determine the width of the search bar and smoothly animate it
    const [ref, { width: measuredWidth }] = useMeasure();


    // Use opacity to determine whether to fade out the emphasis words; clamp the spring to not overshoot
    const [{opacity}, opacitySpring] = useSpring(()=>({
        from: { opacity: 1},
        onRest: (result) => {
            if (result.value.opacity === 0) {
                setFadedOut(true);
            } else {
                setFadedOut(false);
            }
        },
        config: {
            clamp: true,
            ...config.stiff
        },
    }))

    // Separate spring for scale that can bounce over/under
    const [{transform}, transformSpring] = useSpring(()=>({
        from: { transform: "scale(1)" },
        config: config.stiff,
    }))

    // Separate spring for width that is invoked in different useEffect
    const [{width}, widthSpring] = useSpring(()=>({
        from: { width: measuredWidth },
        config: config.stiff,
    }))

    // Animate the selected word by changing opacity and scale to 0, then changing the word index, and then animating back to 1
    const shortTimeout = 3000;
    const longTimeout = 4500;
    useEffect(() => {
        if (!fadedOut) {
            const timeout = setTimeout(() => {
                opacitySpring.start({opacity: 0});
                transformSpring.start({transform: "scale(0)"});
            }, wordIndex === words.length - 1 ? longTimeout : shortTimeout);
            return () => clearTimeout(timeout);
        } else {
            return () => {};
        }
    }, [opacitySpring, fadedOut, words.length, wordIndex, transformSpring]);

    // When we are fully faded out, change word index and animate the opacity to 1
    useEffect(() => {
        if (fadedOut) {
            setWordIndex(prevIndex => (prevIndex + 1) % words.length);
            opacitySpring.start({opacity: 1});
            transformSpring.start({transform: "scale(1)"});
        }
    }, [opacitySpring, fadedOut, words.length, transformSpring]);

    useEffect(() => {
        widthSpring.start({width: measuredWidth});
    }, [measuredWidth, widthSpring]);

    return (
        <animated.div id="search-bar" >
            <h1>Tools for the future of&nbsp;</h1>
            <animated.h1 className="h1-callout" style={{opacity, transform, width}}>{words[wordIndex]}</animated.h1>
            <Logo />
            <h1 ref={ref} style={{visibility: "hidden", position: "absolute", top: 0, left: 0}}>{words[wordIndex]}</h1>
        </animated.div>
    )
}