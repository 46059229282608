/*
Footer for our website that shows the (c) and the current year
 */
import './Footer.css';

export default function Footer() {
    return (
        <div id="footer-container">
            <div className="footer-column">
                <div className="footer-text">
                    © {new Date().getFullYear()} All Rights Reserved
                </div>
            </div>
            <div className={`footer-column`}>
                <div className="footer-text">
                    {"Made with 💜 in San Francisco"}
                </div>
            </div>

        </div>
    );
}