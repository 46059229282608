import React from 'react';
import './App.css';
import SearchBar from './components/SearchBar';
import Footer from './components/Footer';

function App() {

  return (
    <div id="app-container">
        <SearchBar />
        <div className="subtext">
            coming soon...
        </div>
        <Footer />
    </div>
  );
}

export default App;
